<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('MENU.barcode_setting') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

      <div class="row">
        <div class="col-md-12">
          <div class="card card-custom">
            <div class="card-body row">
                <div class="col-lg-12 mb-5">
                  <label>{{ $t('barcode_setting.type') }}</label>
                    <select name="" id="type" v-model="data.type" type="text" class="custom-select" :class="validation && validation.type ? 'is-invalid' : ''">>
                      <option :value="null" selected disabled>{{ $t('global.all') }}</option>
                      <option v-for="row in type_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                    </select>
                    <span v-if="validation && validation.type" class="fv-plugins-message-container invalid-feedback">
                        {{ validation.type[0] }}
                    </span>
                </div>
                <div class="col-lg-12 mb-5">
                  <label>{{ $t('barcode_setting.number_parts') }}</label>
                  <div class="input-group">
                    <input type="text" class="form-control" :class="validation && validation.number_parts ? 'is-invalid' : ''" v-model="data.number_parts" :placeholder="$t('barcode_setting.number_parts')"/>
                    <span v-if="validation && validation.number_parts" class="fv-plugins-message-container invalid-feedback">{{ validation.number_parts[0] }}</span>
                  </div>
                </div>
                <div class="col-lg-12 mb-5">
                  <!-- <label>{{ $t('barcode_setting.remove_zero_item_code') }}</label> -->
                  <div class="col-lg-6 mb-5">
                    <b-form-checkbox size="lg" v-model="data.remove_zero_item_code" name="check-button" switch>{{ $t('barcode_setting.remove_zero_item_code') }}</b-form-checkbox>
                  </div>
                </div>

            </div>
          </div>
        </div>
        
        
      </div>
      <div class="row mt-5">
        <div class="col-md-12">

          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="mt-2">{{ $t('barcode_setting.details') }}</h6>
              <button type="button" class="btn btn-primary mr-2" @click="addToRepeater">{{ $t('add_more') }}</button>
          </div>
          <div class="bg-white">
            <table class="table" @keyup.alt.enter="addToRepeater" @keyup.alt.46="deleteBarcodeFromRepeater(0)">
              <thead>
              <tr class="text-center">
                <th width="20%">{{ $t('barcode_setting.number_part') }}</th>
                <th width="20%">{{ $t('barcode_setting.symbols_type') }}</th>
                <th width="20%">{{ $t('barcode_setting.symbols_number') }}</th>
                <th width="20%">{{ $t('barcode_setting.another_value_symbols') }}</th>
                <th width="20%">{{ $t('barcode_setting.number_symbols_boxes') }}</th>
                <th width="20%" class="text-center">

                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(barcode, index) in barcodes" :key="index">
                <td>
                  <!-- <input type="text" v-model="barcode.number_part" class="form-control" :placeholder="$t('barcode_setting.number_part')"/> -->
                  <select name="" id="type" v-model="barcode.number_part" class="custom-select">
                    <option :value="null" selected disabled>{{ $t('global.all') }}</option>
                    <option :value="1">1</option>
                    <option :value="2">2</option>
                    <option :value="3">3</option>
                    <option :value="4">4</option>
                    <option :value="5">5</option>
                    <option :value="6">6</option>
                    <option :value="7">7</option>
                  </select>
                </td>
                <td class="text-center">
                  <!-- <input type="text" v-model="barcode.symbols_type" class="form-control" :placeholder="$t('barcode_setting.symbols_type')"/> -->
                  <select name="" id="type" v-model="barcode.symbols_type" class="custom-select">
                    <option :value="null" selected disabled>{{ $t('global.all') }}</option>
                    <option v-for="row in symbols_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                  </select>
                </td>
                <td class="text-center">
                  <!-- <input type="text" v-model="barcode.symbols_number" class="form-control" :placeholder="$t('barcode_setting.symbols_number')"/> -->
                  <select name="" id="type" v-model="barcode.symbols_number" class="custom-select">
                    <option :value="null" selected disabled>{{ $t('global.all') }}</option>
                    <option :value="1">1</option>
                    <option :value="2">2</option>
                    <option :value="3">3</option>
                    <option :value="4">4</option>
                    <option :value="5">5</option>
                    <option :value="6">6</option>
                    <option :value="7">7</option>
                  </select>
                </td>
                <td class="text-center">
                  <input type="text" v-model="barcode.another_value_symbols" class="form-control" :placeholder="$t('barcode_setting.another_value_symbols')"/>
                </td>
                <td class="text-center">
                  <input type="text" v-model="barcode.number_symbols_boxes" class="form-control" :placeholder="$t('barcode_setting.number_symbols_boxes')"/>
                </td>
                <td class="text-center">
                  <v-icon class="text-danger" @click="deleteFromRepeater(index)">mdi-delete</v-icon>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "form-barcode_setting",
  data() {
    return {
      mainRoute: 'settings/barcode_setting',
      data: {
        type: null,
        number_parts: null,
        number_symbols: null,
        remove_zero_item_code: null,
      },
      idEdit: null,
      isEditing: false,
      validation: null,
      type_list : [],
      symbols_list : [],
      barcodes : [],
      repeater_barcodes : {id:null, number_part: null,symbols_type : null,symbols_number : null,another_value_symbols : null,number_symbols_boxes : null},
    };
  },

  methods: {
    save() {
      if (this.idEdit) {
        this.update();
      } else {
        this.create();
      }
    },
    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
        barcodes: this.barcodes
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.getData();
      }).catch((error) => {
        this.validation = error.response ? error.response.data.errors : null;
      });
    },
    update() {
      ApiService.put(this.mainRoute + '/' + this.idEdit, {
        ...this.data,
        barcodes: this.barcodes
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
      }).catch((error) => {
        this.validation = error.response ? error.response.data.errors : null;
      });
    },
    async getData() {
      await ApiService.get(this.mainRoute).then((response) => {
        this.isEditing = true;
        this.data = response.data.data;
        this.barcodes = response.data.data.barcodes ? response.data.data.barcodes : [];

        if ((response.data.data.barcodes && response.data.data.barcodes.length <= 0) || !response.data.data.barcodes) {
          this.addToRepeater();
        }

      });
    },

    addToRepeater() {
      this.barcodes.unshift(this.repeater_barcodes);
      this.repeater_barcodes = {id:null,number_part: null,symbols_type : null,symbols_number : null,another_value_symbols : null,number_symbols_boxes : null};
    },

    // deleteItem(item) {
    //   // this.$confirmAlert('', this.actionDelete, item);
    //   console.log(item);
    // },
    deleteFromRepeater(index) {
      this.barcodes.splice(index, 1);
    },
    getTypeList() {
      ApiService.get('base/dependency/barcode_type_list').then(response => {
        this.type_list = response.data.data;
      });
    },
    getSymbolsList() {
      ApiService.get('base/dependency/symbols_type_list').then(response => {
        this.symbols_list = response.data.data;
      });
    },

  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.barcode_setting")}]);
        
    this.getTypeList();
    this.getSymbolsList();

    let promise = this.getData();
    let that = this;
    Promise.all([promise]).then(() => {
      if ((that.barcodes && that.barcodes.length <= 0) || !that.barcodes) {
        that.addToRepeater();
      }
    });
   
  },
};
</script>


